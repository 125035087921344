<template>
  <div class="post-author grid pos-r" :class="{ load: isLoad }" v-click-outside="closeAction">
    <BaseLoad v-if="isLoad" class="rel sm" />
    <template v-else>
      <button
        v-if="currentAuthor"
        class="btn transparent-grey pl-10 ta-left cut grid gtc-auto-1"
        v-tippy="
          `Будет опубликованно от имени ${
            currentAuthor.type === 'Team'
              ? 'команды'
              : currentAuthor.type === 'Community'
              ? 'сообщества'
              : 'пользователя'
          }:<br><div class='t-secondary'>${currentAuthor.name}</div>`
        "
        @click.prevent="toggleAction"
      >
        <div class="cover ava-24">
          <img :src="currentAuthor.avatar" />
        </div>
        <div class="grid cut">
          <b class="cut">{{ currentAuthor.name }}</b>
          <small>{{ currentAuthor.typeName }}</small>
        </div>
      </button>
      <button
        v-else
        class="btn transparent-grey pl-10 ta-left cut grid gtc-auto-1"
        v-tippy="`Выберите от чьего имени публиковать пост`"
        @click.prevent="toggleAction"
      >
        <div class="cover ava-24">
          <img src="@/assets/img/ava.svg" />
        </div>
        <div class="grid cut">
          <b class="cut">Выберите сообщество</b>
          <!-- <small>{{ currentAuthor.typeName }}</small> -->
        </div>
      </button>

      <div v-if="isOpen" class="post-author__body box p-5 grid pos-a">
        <!-- <button
          v-if="profile"
          class="btn transparent-grey pl-10 ta-left cut grid gtc-auto-1 lg"
          :class="{ active: propObjType === 'User' || propObjType === 'App\\Models\\User' }"
          @click.prevent="setOwner(profile)"
        >
          <div class="cover ava-30">
            <img :src="profile.avatar" />
          </div>
          <div class="grid cut">
            <div class="cut">{{ profile.fullname }}</div>
            <small>Пользователь</small>
          </div>
        </button> -->
        <button
          v-for="item of items"
          :key="item.id"
          class="btn transparent-grey pl-10 ta-left cut grid gtc-auto-1 lg"
          :class="{
            active: (propObjType === item.type || propObjType === 'App\\Models\\' + item.type) && propObjId === item.id
          }"
          @click.prevent="setOwner(item)"
        >
          <div class="cover ava-30">
            <img :src="item.avatar" />
          </div>
          <div class="grid cut">
            <div class="cut">{{ item.name }}</div>
            <small>{{ item.type === 'Team' ? 'Команда' : 'Сообщество' }}</small>
          </div>
        </button>
      </div>
    </template>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { defineEmits, defineProps, ref, computed, toRefs } from 'vue'
import { BaseLoad } from '@/components'

// Emits
const emits = defineEmits(['update:obj_type', 'update:obj_id'])

// Props
const props = defineProps({
  obj_type: {
    type: String,
    default: ''
  },
  obj_id: {
    type: Number,
    default: 0
  }
})

// Data
const { obj_type, obj_id } = toRefs(props)
const store = useStore()
const route = useRoute()
const isLoad = ref(false)
const isOpen = ref(false)

// Computed
const profile = computed(() => store.getters.profile)
const items = computed(() => store.getters['helpers/adminList'])
const propObjType = computed({
  get: () => obj_type.value,
  set: (val) => emits('update:obj_type', val)
})
const propObjId = computed({
  get: () => obj_id.value,
  set: (val) => emits('update:obj_id', val)
})
const currentAuthor = computed(() => {
  const cur = items.value?.length
    ? items.value.find(
        (el) =>
          (el.id === propObjId.value || el.id === Number(route.params.id)) &&
          (el.type === propObjType.value || el.type === propObjType.value.split('\\')[2])
      )
    : null

  if (cur) {
    switch (propObjType.value) {
      case 'User':
      case 'App\\Models\\User':
        return {
          id: propObjId.value,
          name: profile.value?.fullname,
          avatar: profile.value?.avatar,
          type: propObjType.value,
          typeName: 'Пользователь'
        }

      default:
        return {
          id: cur?.id,
          name: cur?.name,
          avatar: cur?.avatar,
          type: propObjType.value,
          typeName: propObjType.value === 'Team' || propObjType.value === 'App\\Models\\Team' ? 'Команда' : 'Сообщество'
        }
    }
  } else {
    return null
    // return {
    //   id: propObjId.value,
    //   name: profile.value?.fullname,
    //   avatar: profile.value?.avatar,
    //   type: 'User',
    //   typeName: 'Пользователь'
    // }
  }
})

// Created
getList()

// Methods
async function getList() {
  isLoad.value = true
  try {
    await store.dispatch('helpers/GET_ADMIN_GROUPS')
  } catch (error) {
    console.log(error)
  } finally {
    isLoad.value = false
  }
}
async function toggleAction() {
  isOpen.value = !isOpen.value
}
function closeAction() {
  isOpen.value = false
}
function setOwner(owner) {
  propObjId.value = owner.id
  propObjType.value = !owner?.type ? 'User' : owner.type
  closeAction()
}
</script>

<style lang="scss" scoped>
.post-author {
  width: auto;

  &.load {
    width: auto;
  }

  // &__btn {}

  &__body {
    z-index: 2001;
    box-shadow: var(--box-shadow);
    width: 300px;
    max-height: 300px;
    overflow: auto;
    bottom: 100%;
    left: 0;

    .btn {
      font-size: 14px;

      small {
        color: var(--grey-ed);
      }
      &.active small {
        color: #fff;
      }
    }
  }
}
</style>
